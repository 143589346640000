@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

img {
  height: auto !important;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  background-color: rgba(255, 255, 255, 0) !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.pd100 {
  padding: 100px 0;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media only screen and (max-width: 1000px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media only screen and (max-width: 1000px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  position: relative;
  z-index: 99;
}

.header {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .header {
    background-color: #fff;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.header-inner {
  padding: 10px;
}

@media only screen and (max-width: 1000px) {
  .header-inner {
    padding: 0;
  }
}

.header-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.header-logowrap {
  max-width: 250px;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .header-logowrap {
    max-width: 190px;
  }
}

.header-logo {
  max-width: 250px;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .header-logo {
    max-width: 190px;
    margin-left: 10px;
    line-height: 60px;
  }
}

.header-logo img {
  width: 100% !important;
  max-width: 100% !important;
}

.header-right {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .header-right {
    margin-top: 40px;
    justify-content: center;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .header-right {
    flex-direction: column;
  }
}

.header-right a {
  display: block;
}

.header-tel {
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  .header-tel {
    margin-right: 2%;
  }
}

@media only screen and (max-width: 640px) {
  .header-tel {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.header-nav {
  width: 100%;
  max-width: 1180px;
  margin: 10px 0 0 auto;
}

@media only screen and (max-width: 1000px) {
  .header-nav {
    position: fixed;
    left: 0;
    top: 70px;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    margin: 0 auto;
    padding-top: 10px;
    display: none;
  }
}

.header-nav ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .header-nav ul {
    flex-wrap: wrap;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.header-nav ul li {
  flex-grow: 1;
}

@media only screen and (max-width: 1000px) {
  .header-nav ul li {
    text-align: left;
    padding-left: 20px;
    width: 49%;
    flex-grow: 0;
    padding-bottom: 25px;
    margin-top: 30px;
    border-bottom: 1px dashed #8bbf43;
  }
}

.header-nav ul li a {
  display: block;
  color: #333 !important;
  transition: all .3s ease;
}

.header-nav ul li a:hover {
  color: #8bbf43 !important;
}

.main {
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .main {
    margin-top: 70px;
  }
}

.main img {
  width: 100% !important;
  max-width: 100% !important;
}

.main-photo {
  position: relative;
}

.main-photo img {
  min-height: 500px;
  object-fit: cover;
}

.main-text {
  position: absolute;
  left: 5%;
  bottom: 17%;
  width: 68%;
  max-width: 1221px;
  padding-right: 5%;
}

@media only screen and (max-width: 1000px) {
  .main-text {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .main-text {
    width: 98%;
    padding-right: 4%;
    left: 3%;
    bottom: 17%;
  }
}

.main-text img {
  min-height: inherit;
}

.res-menu {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  border-radius: 0px;
  cursor: pointer;
  background-color: #8bbf43;
  display: none;
  z-index: 999;
}

@media only screen and (max-width: 1000px) {
  .res-menu {
    display: block;
  }
}

.res-menu div {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  height: 2px;
  width: 35%;
}

.res-menu div:nth-of-type(1) {
  transform: translateY(-7px);
  transition: all .3s ease;
}

.res-menu div:nth-of-type(3) {
  transform: translateY(7px);
  transition: all .3s ease;
}

.res-menu.active div:nth-of-type(1) {
  transform: rotate(45deg);
}

.res-menu.active div:nth-of-type(2) {
  opacity: 0;
}

.res-menu.active div:nth-of-type(3) {
  transform: rotate(-45deg);
}

#mainArea {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

@media only screen and (max-width: 1000px) {
  #mainArea {
    margin-top: 70px;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #333;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 180px 0;
  font-family: source-han-serif-japanese, serif;
  font-weight: 900;
  font-style: normal;
}

#local-keyvisual h1:before {
  position: absolute;
  content: "";
  bottom: 80px;
  left: 0;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  #local-keyvisual h1 {
    padding: 140px 0;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    padding: 120px 0;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.2em;
  }
}

#local-keyvisual h1 span {
  display: block;
  margin: 0 auto;
  max-width: 1200px;
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}

.pan1 ol {
  display: flex;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  padding: 60px 0 40px;
  background-color: #8bbf43;
  color: #fff;
}

.footer {
  width: 100%;
}

.footer-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .footer-inner {
    flex-direction: column;
  }
}

.footer-left {
  text-align: left;
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  .footer-left {
    text-align: center;
    margin-right: 0;
  }
}

.footer-info {
  margin-top: 30px;
  font-size: 14px;
}

.footer-nav {
  max-width: 600px;
  width: 100%;
}

.footer-nav ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .footer-nav ul {
    margin-top: 30px;
    flex-direction: column;
  }
}

.footer-nav ul li {
  padding: 20px;
}

@media only screen and (max-width: 640px) {
  .footer-nav ul li {
    padding: 10px 0;
  }
}

.footer-nav ul li a {
  color: #fff !important;
  font-size: 14px;
}

#copy {
  margin-top: 60px;
  display: block;
}

html {
  visibility: hidden;
}

html.wf-active, html.loading-delay {
  visibility: visible;
}

.title-green {
  color: #8bbf43;
  display: inline !important;
}

.cont-h2 h2 {
  font-weight: bold;
  font-size: 38px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont-h2 h2 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 640px) {
  .cont-h2 h2 {
    font-size: 32px;
  }
}

.cont-h2 h2 .sub-title {
  color: #8bbf43;
  display: block;
  font-size: 16px;
  margin-top: 15px;
}

.cont_01 {
  padding: 100px 0;
}

.cont_01-inner {
  position: relative;
  max-width: 1100px;
  margin: 0 0 0 auto;
  padding: 0 10px;
}

.cont_01-imgwrap {
  width: 60%;
  margin: 0 0 0 auto;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_01-imgwrap {
    width: 100%;
  }
}

.cont_01-imgwrap img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_01-h2 {
  width: 50%;
  padding: 50px 30px;
  background-color: #fff;
  border: 1px solid #8bbf43;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .cont_01-h2 {
    position: static;
    width: 100%;
    transform: translateY(0);
    border: none;
    padding: 30px 0 0;
  }
}

.cont_01-h2 h2 {
  font-size: 30px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01-h2 h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_01-h2 h2 {
    font-size: 26px;
  }
}

.cont_01-h2 div {
  margin-top: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_01-h2 div {
    margin-top: 25px;
  }
}

.cont_02 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background-image: url(../images/home/recommend.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 300px;
  position: relative;
  z-index: -2;
}

.cont_02-inner {
  padding: 0 20px;
}

.cont_02-h2 {
  text-align: center;
}

.cont_02-h2 h2 {
  font-size: 34px;
  display: inline-block;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_02-h2 h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_02-h2 h2 {
    font-size: 30px;
  }
}

.cont_02-h2 h2:after {
  position: absolute;
  content: "";
  background-color: rgba(242, 169, 53, 0.7);
  width: 100%;
  height: 10px;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.cont_02-list {
  margin-top: 50px;
}

.cont_02-list ul li {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 20px 0 20px 70px;
  font-size: 20px;
  width: 100%;
  max-width: 900px;
  margin: 20px auto 0;
  border-radius: 10px;
}

@media only screen and (max-width: 834px) {
  .cont_02-list ul li {
    font-size: 18px;
    padding: 15px 15px 15px 30px;
  }
}

.cont_02-list ul li img {
  width: 50px !important;
  margin-right: 70px;
}

@media only screen and (max-width: 834px) {
  .cont_02-list ul li img {
    margin-right: 30px;
  }
}

.cont_03 {
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  padding: 60px 100px;
  margin: 0 auto;
  margin-top: -200px;
  border-radius: 10px;
}

@media only screen and (max-width: 834px) {
  .cont_03 {
    padding: 80px 20px;
  }
}

.cont_03-h2 div {
  margin-top: 20px;
}

.cont_03-img {
  margin-top: 40px;
}

.cont_03-img img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_04 {
  margin-top: 100px;
}

.cont_04-flex {
  margin-top: 40px;
}

.cont_04-flex .box {
  width: 25%;
}

@media only screen and (max-width: 834px) {
  .cont_04-flex .box {
    width: 50%;
  }
}

.cont_04-flex .box img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_contact {
  margin-top: 100px;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background-color: #8bbf43;
  padding: 70px 20px;
}

.cont_contact2 {
  max-width: 1080px;
  margin: 80px auto !important;
  border-radius: 10px;
}

.cont_contact-inner {
  background-color: #fff;
  border: 2px solid #f2a935;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 30px;
  border-radius: 10px;
}

.cont_contact-h2 {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #999;
}

.cont_contact-h2 h2 {
  font-size: 36px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_contact-h2 h2 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_contact-h2 h2 {
    font-size: 32px;
  }
}

.cont_contact-btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_contact-btn {
    flex-direction: column;
  }
}

.cont_contact-btn a {
  width: 100% !important;
  display: block;
  height: auto !important;
}

.cont_contact-btn a img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_contact-tel, .cont_contact-mail {
  width: 50%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_contact-tel, .cont_contact-mail {
    width: 65%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_contact-tel, .cont_contact-mail {
    width: 80%;
  }
}

.cont_contact-mail {
  margin-right: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_contact-mail {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.cont_05 {
  margin-top: 100px;
}

.cont_05-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  align-items: flex-start;
}

@media only screen and (max-width: 834px) {
  .cont_05-inner {
    flex-direction: column;
  }
}

.cont_05-left {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_05-left {
    width: 100%;
  }
}

.cont_05-right {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_05-right {
    width: 100%;
    margin-top: 80px;
  }
}

.cont_05-h2 h2 {
  font-size: 30px;
}

.cont_05-flex {
  margin-top: 40px;
}

.cont_05-flex .box {
  width: 50%;
  padding: 10px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_05-flex .box {
    width: 33%;
  }
}

@media only screen and (max-width: 834px) {
  .cont_05-flex .box {
    padding: 10px;
  }
}

.cont_05-flex .box img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_05-btn {
  position: relative;
  display: block;
  background-color: #8bbf43;
  text-align: center;
  color: #fff !important;
  height: 70px;
  line-height: 70px;
  border-radius: 35px;
  width: 80%;
  margin: 40px auto 0;
}

.cont_05-btn:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: "\f0a9";
  font-family: "Font Awesome 5 pro";
}

.cont_banner {
  margin-top: 100px;
}

.cont_banner-inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.cont_banner1 a, .cont_banner2 a, .cont_banner3 a, .cont_banner4 a {
  width: 100% !important;
  height: auto !important;
}

.cont_banner2 {
  margin-top: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_banner2 {
    margin-top: 20px;
  }
}

.cont_banner3 {
  margin-top: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_banner3 {
    margin-top: 20px;
  }
}

.cont_banner-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_banner-flex {
    margin-top: 20px;
  }
}

.cont_banner-flex img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_banner4 {
  width: 50%;
}

.cont_banner4:first-child {
  margin-right: 40px;
}

@media only screen and (max-width: 834px) {
  .cont_banner4:first-child {
    margin-right: 20px;
  }
}

.cont_06 {
  background-color: #f4fbeb;
  padding: 80px 0;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-top: 100px;
}

.cont_06-inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.cont_06 .insta {
  margin-top: 50px;
}

.cont_06 .sns_list {
  padding: 0 20px;
  flex-direction: row;
  border: none;
  flex-wrap: wrap;
}

.cont_06 .sns_list > div {
  width: 25%;
  flex-direction: column;
  border-bottom: none;
  display: block !important;
}

@media only screen and (max-width: 834px) {
  .cont_06 .sns_list > div {
    width: 50%;
    margin-top: 20px;
  }
}

.cont_06 .sns_photo {
  width: 100% !important;
}

.cont_06 .sns_text {
  padding: 0 10px !important;
  width: 100% !important;
  font-size: 14px;
}

.cont_06 .sns_date {
  color: #333 !important;
  font-size: 14px;
}

.cont_07 {
  margin-top: 100px;
}

.cont_07-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 40px auto 0;
}

.cont_07-text {
  background-color: #ccfa8c;
  padding: 25px;
  margin-right: 60px;
  position: relative;
  width: 85%;
}

@media only screen and (max-width: 640px) {
  .cont_07-text {
    width: 80%;
  }
}

.cont_07-text:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  border: 15px solid transparent;
  border-left: 50px solid #ccfa8c;
}

.cont_07-text2 {
  margin-right: 0;
  margin-left: 60px;
}

.cont_07-text2:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  left: auto;
  border: 15px solid transparent;
  border-right: 50px solid #ccfa8c;
}

.cont_07-img {
  width: 15%;
}

@media only screen and (max-width: 640px) {
  .cont_07-img {
    width: 20%;
  }
}

.cont_07-img img {
  width: 100% !important;
  max-width: 100% !important;
}

.cont_08 {
  margin-top: 100px;
}

.cont_08-inner {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.cont_08-news {
  margin-top: 50px;
  width: 100%;
  height: 250px;
  overflow-y: scroll;
}

.cont_08-news dl {
  border-bottom: 1px dashed #8bbf43;
  margin-top: 20px;
  padding-bottom: 15px !important;
}

.cont_08-news dl dt {
  color: #8bbf43;
  background-color: inherit !important;
  border: none !important;
}

.cont_08-news dl dd {
  border: none !important;
}

.cont_map {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.cont_map iframe {
  width: 100% !important;
  vertical-align: bottom;
}

.p2 {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto 80px;
}

.p2-h2 {
  margin-bottom: 40px;
  margin-top: 80px;
}

.p2-h2 h2 {
  font-size: 34px;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 4px solid #ddd;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .p2-h2 h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 640px) {
  .p2-h2 h2 {
    font-size: 30px;
  }
}

.p2-h2 h2:after {
  position: absolute;
  content: "";
  background-color: #8bbf43;
  width: 200px;
  height: 4px;
  left: 0;
  bottom: -4px;
}

.p2-h3 {
  margin-top: 60px;
}

.p2-h3 h3 {
  font-size: 26px;
  color: #8bbf43;
  padding-bottom: 15px;
  border-bottom: 3px dashed #8bbf43;
}

.p2-text {
  line-height: 2;
}

.p2-text a {
  color: #8bbf43;
}

.p2-top-img {
  margin-top: 40px;
}

.p2-contact-img {
  margin-top: 20px;
}

.p2-tel-img {
  margin-top: 10px;
}

.p3 {
  max-width: 1080px;
  margin: 0 auto;
}

.product-list {
  border: 1px solid #8bbf43;
  padding: 30px;
  line-height: 2;
  margin-top: 40px;
}

.company-img {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.company-img .box {
  width: 25%;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .company-img .box {
    width: 50%;
  }
}

.company-img .box img {
  width: 100% !important;
  max-width: 100 !important;
}

.company-yahoo {
  text-align: center;
  margin: 60px auto 0;
  display: flex;
  justify-content: center;
}

.company-yahoo img {
  text-align: center;
  display: block;
  width: 100% !important;
  max-width: 100% !important;
}

.office-text {
  background-color: #f4fbeb;
  padding: 60px;
  margin-top: 40px;
  line-height: 1.8;
}

@media only screen and (max-width: 640px) {
  .office-text {
    padding: 40px 20px;
  }
}

.free-text {
  font-size: 20px;
  line-height: 1.6;
  margin-top: 80px;
}

.free-text span {
  font-size: 26px;
  color: #8bbf43;
  display: block;
  font-weight: bold;
  position: relative;
  padding-bottom: 30px;
}

.free-text span:before {
  position: absolute;
  content: "";
  background-color: #8bbf43;
  width: 100px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}

.before-afterwrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .before-afterwrap {
    flex-direction: column;
  }
}

.before-after {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 50%;
  padding: 10px 15px;
  margin-top: 40px;
}

@media only screen and (max-width: 834px) {
  .before-after {
    width: 100%;
    padding: 5px;
  }
}

.arrow {
  vertical-align: middle;
  padding: 0 25px;
}

@media only screen and (max-width: 834px) {
  .arrow {
    padding: 10px;
  }
}

.arrow img {
  width: 100% !important;
  max-width: 100% !important;
}

.before img,
.after img {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 3px;
}

.mail,
.annot {
  display: none;
}

.mail-form {
  margin-top: 40px;
}

.mail-form dl dt {
  padding: 25px 10px !important;
  align-items: center !important;
}

.mail-form dl dd {
  padding: 25px 10px !important;
}

.mail-form dl .text input {
  height: 250px;
  width: 100% !important;
}
